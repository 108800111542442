import React, {useEffect, useState} from 'react';
import {toast} from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';
import axios from '../../service/axios';
import {Autocomplete, Box, Button, FormControl, TextField, Typography} from '@mui/material';

const Create = () => {
    const navigate = useNavigate();

    const [title, setTitle] = useState('');
    const [moduleId, setModuleId] = useState('');
    const [description, setDescription] = useState('');

    const [modules, setModules] = useState([]);
    const [groups, setGroups] = useState([]);

    const [selectedGroup, setSelectedGroup] = useState(null);

    const [selectedWeight, setSelectedWeight] = useState('');

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get('groups/')
            .then(response => {
                setLoading(false);
                setGroups(response.data.map(group => ({
                    value: group.id,
                    label: group.name
                })));
            })
            .catch(error => {
                console.warn(error);
                setLoading(false);
            });
    }, []);

    const loadModulesForGroup = (groupId) => {
        setLoading(true);
        axios.get(`module/${groupId}/modules/`)
            .then(response => {
                setModules(response.data.map(module => ({
                    value: module.id,
                    label: module.title
                })));
                setLoading(false);
            })
            .catch(error => {
                console.warn(error);
                setLoading(false);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('/criterion/', {
            title,
            weight: parseFloat(selectedWeight),
            module: moduleId,
            description,
            group: selectedGroup ? selectedGroup.value : null
        })
            .then(response => {
                toast.success(response.data.message, {
                    position: 'top-right',
                });
                navigate(`/criteria/`);
            })
            .catch(error => {
                console.warn(error);
                toast.error(error.response?.data?.message || 'Заполните все поля', {
                    position: 'top-right',
                });
            });
    };

    return (
        <Box sx={{p: 3}}>
            <Typography variant="h3" gutterBottom>
                Создание критерия
            </Typography>
            <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal">
                    <Autocomplete
                        options={groups}
                        value={selectedGroup}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, newValue) => {
                            setSelectedGroup(newValue);
                            if (newValue) {
                                loadModulesForGroup(newValue.value);
                            }
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Группа" variant="standard" required={true}/>
                        )}
                        disabled={loading}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                    />
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <Autocomplete
                        options={modules}
                        value={modules.find(module => module.value === moduleId) || null}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, newValue) => {
                            setModuleId(newValue ? newValue.value : '');
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Модуль" variant="standard" required={true}/>
                        )}
                        disabled={loading || !selectedGroup}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                    />
                </FormControl>

                <TextField
                    fullWidth
                    variant="standard"
                    margin="normal"
                    label="Название критерия"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    disabled={loading || !selectedGroup}
                    required={true}
                />

                <TextField
                    fullWidth
                    variant="standard"
                    margin="normal"
                    label="Описание"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    disabled={loading || !selectedGroup}
                    placeholder="Поле может быть пустым"
                    multiline
                    rows={4}
                />

                <FormControl fullWidth margin="normal">
                    <Autocomplete
                        options={[
                            {value: "", label: "Выберите балл..."},
                            {value: "2", label: "2"},
                            {value: "3", label: "3"},
                            {value: "4", label: "4"}
                        ]}
                        value={{value: selectedWeight, label: selectedWeight}}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, newValue) => {
                            setSelectedWeight(newValue ? newValue.value : '');
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Балл" variant="standard" required={true}/>
                        )}
                        disabled={loading || !selectedGroup}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                    />
                </FormControl>

                <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    disabled={loading || !selectedGroup}
                    fullWidth
                    sx={{mt: 2}}
                >
                    Создать
                </Button>
            </form>
        </Box>
    );
};

export default Create;