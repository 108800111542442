import React, {useEffect, useState} from 'react';
import axios from "../../service/axios";
import {toast} from "react-hot-toast";
import {
    Box,
    Button,
    Divider,
    Fab,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {useDispatch, useSelector} from "react-redux";
import {setButtons} from "../../redux/slice/themeSlice";

const Index = () => {
    const dispatch = useDispatch();
    const [students, setStudents] = useState([]);
    const [modules, setModules] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedModule, setSelectedModule] = useState(null);
    const [moduleCriteria, setModuleCriteria] = useState([]);
    const [savedResults, setSavedResults] = useState([]);
    const [loading, setLoading] = useState(true);

    const inputMethod = useSelector((state) => state.theme.buttons);

    const setInputMethod = (value) => {
        dispatch(setButtons(value));
    };

    const getModules = async (groupId) => {
        try {
            const response = await axios.get(`module/${groupId}/modules/`);
            setModules(response.data);
        } catch (error) {
            console.error('Ошибка загрузки модулей:', error);
        }
    };

    const handleGroupChange = async (event) => {
        const groupId = event.target.value;
        setSelectedGroup(groupId);
        if (groupId) {
            try {
                const response = await axios.get(`groups/${groupId}/students/`);
                setStudents(response.data);
                setLoading(false);
                getModules(groupId);
            } catch (error) {
                console.warn(error);
            }
        } else {
            setStudents([]);
        }
    };

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const response = await axios.get('groups/');
                setGroups(response.data);
            } catch (error) {
                console.error('Ошибка загрузки групп:', error);
            }
        };

        fetchGroups();
    }, []);

    useEffect(() => {
        const fetchSavedResults = async () => {
            if (selectedStudent && selectedModule && selectedGroup) {
                try {
                    const response = await axios.get(`results/${selectedStudent}/${selectedModule}/`);
                    if (response.data.length > 0) {
                        setSavedResults(response.data);
                    } else {
                        console.warn('Результаты запроса пусты.');
                    }
                } catch (error) {
                    console.error('Ошибка при загрузке сохраненных результатов:', error);
                }
            } else {
                setSavedResults([]);
            }
        };
        fetchSavedResults();
    }, [selectedStudent, selectedModule, selectedGroup]);

    useEffect(() => {
        const fetchModuleCriteria = async () => {
            if (!selectedModule || !selectedStudent || !selectedGroup) {
                setSavedResults([]);
                setModuleCriteria([]);
            } else {
                try {
                    const response = await axios.get(`criterion/get/${selectedModule}/`);
                    setModuleCriteria(response.data);

                    setSavedResults(prevResults => {
                        return response.data.map(criterion => {
                            const existingResult = prevResults.find(result => result.criterion === criterion.id);
                            return existingResult || {
                                student: selectedStudent,
                                criterion: criterion.id,
                                score: 0
                            };
                        });
                    });
                } catch (error) {
                    console.error('Ошибка при загрузке критериев:', error);
                }
            }
        };

        fetchModuleCriteria();
    }, [selectedModule, selectedStudent, selectedGroup]);

    const handleScoreChange = (criterion, value) => {
        let score;
        if (value === 2) {
            score = criterion.weight; // Устанавливаем значение на основе веса критерия
        } else if (value === 1) {
            score = (criterion.weight * 2.67) / 4; // Устанавливаем значение для 1
        } else if (value === 0) {
            score = 0;
        }

        const updatedSavedResults = savedResults.map(result => {
            if (result.criterion === criterion.id) {
                return {...result, score};
            }
            return result;
        });

        setSavedResults(updatedSavedResults);
    };


    const handleStudentChange = (event) => {
        setSelectedStudent(event.target.value);
    };

    const handleModuleChange = (event) => {
        setSelectedModule(event.target.value);
    };

    const handleMaxScore = (criterion) => {
        const maxScore = criterion.weight > 2 ? 2 : criterion.weight;
        handleScoreChange(criterion, maxScore);
    };

    const handleSubmit = async (e) => {
        toast.dismiss();
        e.preventDefault();

        try {
            const payload = moduleCriteria.map(criterion => {
                const savedResult = savedResults.find(result => result.criterion === criterion.id);
                const score = savedResult ? savedResult.score : 0; // Убедитесь, что score корректно устанавливается
                return {
                    student: selectedStudent,
                    criterion: criterion.id,
                    score: score
                };
            });
            await axios.put(`results/${selectedStudent}/${selectedModule}/`, payload);
            toast.success('Результаты сохранены', {
                position: 'top-right'
            });
        } catch (error) {
            console.error('Ошибка при сохранении результатов:', error.response.data.message);
            toast.error(error.response.data.message);
        }
    };


    return (
        <Box onSubmit={handleSubmit} sx={{p: 2}}>
            <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 3}}>
                <FormControl fullWidth sx={{mx: 1}}>
                    <InputLabel id="group-select-label">Группа</InputLabel>
                    <Select
                        variant={'standard'}
                        labelId="group-select-label"
                        value={selectedGroup || ''}
                        onChange={handleGroupChange}
                        displayEmpty
                    >
                        {groups.map(group => (
                            <MenuItem key={group.id} value={group.id}>
                                {group.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth sx={{mx: 1}}>
                    <InputLabel id="student-select-label">Студент</InputLabel>
                    <Select
                        variant={'standard'}
                        labelId="student-select-label"
                        value={selectedStudent || ''}
                        onChange={handleStudentChange}
                    >
                        {students.map(student => (
                            <MenuItem key={student.id} value={student.id}>
                                {student.full_name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth sx={{mx: 1}}>
                    <InputLabel id="module-select-label">Модуль</InputLabel>
                    <Select
                        variant={'standard'}
                        labelId="module-select-label"
                        value={selectedModule || ''}
                        onChange={handleModuleChange}
                    >
                        {modules.map(module => (
                            <MenuItem key={module.id} value={module.id}>
                                {module.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Box>
                <FormControl fullWidth sx={{mx: 1}}>
                    <FormLabel id="input-method">Оформление оценок</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="input-method"
                        name="input-method"
                        value={inputMethod}
                        onChange={(e) => setInputMethod(e.target.value)}
                    >
                        <FormControlLabel value="input" control={<Radio/>} label="Ввод"/>
                        <FormControlLabel value="buttons" control={<Radio/>} label="Кнопки"/>
                    </RadioGroup>
                </FormControl>
            </Box>
            <Divider/>
            <Box sx={{
                border: '1px solid', borderColor: 'divider', my: 2,
                borderRadius: 1,
                p: 1,
                boxShadow: 1
            }}>
                {!loading && moduleCriteria.map((criterion) => {
                    const savedResult = savedResults.find(result => result.criterion === criterion.id);
                    return (
                        <Box key={criterion.id} sx={{display: 'flex', alignItems: 'center', my: 2}}>
                            <Box sx={{flex: 1}}>
                                <Typography variant="body1">
                                    {criterion.title} (<span>{criterion.weight}</span>):
                                </Typography>
                                {criterion.description && (
                                    <Typography variant="body2" color="textSecondary">
                                        {criterion.description.split('\n').map((line, index) => (
                                            <span key={index}>
                                                {line.trim()}
                                                <br/>
                                            </span>
                                        ))}
                                    </Typography>
                                )}
                            </Box>
                            <Box sx={{display: 'flex', alignItems: 'end', flex: 1}}>
                                {inputMethod === 'input' ? (
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="Оценка"
                                        variant={'standard'}
                                        size="small"
                                        value={savedResult ? savedResult.score : ""}
                                        onChange={(e) => handleScoreChange(criterion, e.target.value)}
                                        sx={{mr: 1}}
                                    />
                                ) : (
                                    <Box sx={{display: 'flex', alignItems: 'end', flex: 1}}>
                                        {[0, 1, 2].map((value) => {
                                            // Используем значение score из сохраненных результатов, чтобы определить, какая кнопка активна
                                            const scoreValue = value === 2 ? criterion.weight : value === 1 ? (criterion.weight * 2.67) / 4 : 0;

                                            return (
                                                <Button
                                                    key={value}
                                                    variant={savedResult?.score === scoreValue ? "contained" : "outlined"}
                                                    color="primary"
                                                    onClick={() => handleScoreChange(criterion, value)}
                                                    sx={{mr: 1}}
                                                >
                                                    {value}
                                                </Button>
                                            );
                                        })}
                                    </Box>
                                )}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleMaxScore(criterion)}
                                >
                                    <DoneAllIcon/>
                                </Button>
                            </Box>
                        </Box>
                    );
                })}
            </Box>

            {moduleCriteria && <Fab
                variant="extended"
                color="success"
                aria-label="Сохранить"
                sx={{position: 'fixed', bottom: 100, right: 16}}
                onClick={handleSubmit}
            >
                <SaveIcon sx={{mr: 1}}/>
                Сохранить
            </Fab>}
        </Box>
    );
};

export default Index;
