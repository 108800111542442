import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../service/axios';
import { toast } from 'react-hot-toast';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';

const Edit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [moduleId, setModuleId] = useState('');
    const [modules, setModules] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState('');
    const [selectedWeight, setSelectedWeight] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`criterion/${id}/`)
            .then((response) => {
                const data = response.data;
                setTitle(data.title);
                setDescription(data.description);
                setSelectedWeight(data.weight.toString());
                setModuleId(data.module.id);
                setSelectedGroup(data.group.id);
                loadModulesForGroup(data.group.id);
                setLoading(false);
            })
            .catch((error) => {
                console.warn(error);
            });

        axios.get('groups/')
            .then((response) => {
                setGroups(response.data.map(group => ({
                    value: group.id,
                    label: group.name
                })));
            })
            .catch((error) => {
                console.warn(error);
            });
    }, [id]);

    const loadModulesForGroup = (groupId) => {
        setLoading(true);
        axios.get(`module/${groupId}/modules/`)
            .then((response) => {
                setModules(response.data.map(module => ({
                    value: module.id,
                    label: module.title
                })));
                setLoading(false);
            })
            .catch((error) => {
                console.warn(error);
                setLoading(false);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        toast.dismiss();
        axios.put(`/criterion/${id}/`, {
            title,
            weight: parseFloat(selectedWeight),
            module: moduleId,
            description,
            group: selectedGroup,
        })
            .then((response) => {
                toast.success(response.data.message);
                navigate(`/criteria/`);
            })
            .catch((error) => {
                console.warn(error.response.data.message);
                toast.error(error.response.data.message || 'Ошибка при сохранении');
            });
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h3" gutterBottom>
                Редактирование критерия
            </Typography>
            <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="group-select-label">Группа</InputLabel>
                    <Select
                        variant="standard"
                        labelId="group-select-label"
                        value={selectedGroup}
                        onChange={(e) => {
                            const groupId = e.target.value;
                            setSelectedGroup(groupId);
                            loadModulesForGroup(groupId);
                        }}
                        displayEmpty
                        disabled={loading}
                        inputProps={{ 'aria-label': 'Group' }}
                    >
                        {groups.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <InputLabel id="module-select-label">Модуль</InputLabel>
                    <Select
                        labelId="module-select-label"
                        value={moduleId}
                        variant="standard"
                        onChange={(e) => setModuleId(e.target.value)}
                        displayEmpty
                        disabled={loading || !selectedGroup}
                        inputProps={{ 'aria-label': 'Module' }}
                    >
                        {modules.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    fullWidth
                    margin="normal"
                    label="Название критерия"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    disabled={loading}
                    variant="standard"
                />

                <TextField
                    fullWidth
                    margin="normal"
                    label="Описание"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    disabled={loading}
                    placeholder="Поле может быть пустым"
                    multiline
                    rows={4}
                    variant="standard"
                />

                <FormControl fullWidth margin="normal">
                    <InputLabel id="weight-select-label">Балл</InputLabel>
                    <Select
                        variant="standard"
                        labelId="weight-select-label"
                        value={selectedWeight}
                        onChange={(e) => setSelectedWeight(e.target.value)}
                        displayEmpty
                        disabled={loading}
                        inputProps={{ 'aria-label': 'Weight' }}
                    >
                        {[
                            { value: "", label: "Выберите балл..." },
                            { value: 1, label: "1" },
                            { value: 2, label: "2" },
                            { value: 3, label: "3" },
                            { value: 4, label: "4" }
                        ].map(weight => (
                            <MenuItem key={weight.value} value={weight.value}>
                                {weight.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    fullWidth
                    sx={{ mt: 2 }}
                >
                    Сохранить
                </Button>
            </form>
        </Box>
    );
};

export default Edit;